<template>
  <v-card class="card text-center">
    <h2 class="card-text">Confirm payment information</h2>
    <v-form class="font-family-raleway-medium mr-10">
      <v-list-item-title class="align-center d-flex margin font">
        <h3 class="mt-4 mb-4 bold">Payment method</h3>
      </v-list-item-title>
      <v-list-item-title
        v-for="(item, index) of items"
        :key="index"
        class="align-center d-flex"
      >
        <img :src="item.icon" height="17" width="17" />
        <h4 class="ml-5 bold">{{ item.title }}</h4>
      </v-list-item-title>
      <v-row class="ml-6">
        <v-col cols="6">
          <v-radio-group v-model="setBudget" column>
            <v-radio :value="true" color="primary">
              <template v-slot:label>
                <h4 class="ml-2 font" style="color: rgba(0, 0, 0, 6)">
                  Automatic payments
                </h4>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="coles" cols="5">
          <v-radio-group v-model="setBudget" column>
            <v-radio :value="false" color="primary">
              <template v-slot:label>
                <h4 class="ml-2 font" style="color: rgba(0, 0, 0, 6)">
                  Manual payment
                </h4>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-row v-if="!setBudget">
          <span class="ml-7 bolder">Specify your payment amount</span>
          <v-col class="ml-4" cols="11">
            <v-text-field
              class="input-border left"
              height="60"
              outlined
              value="10"
            >
              <template v-slot:append>
                <strong class="pr-5 mt-1 bold">US$ amount</strong>
              </template>
            </v-text-field>
            <p class="bolder left-align">
              You add or select a payment method, and then make a payment in
              advance. <a>Learn more</a>
            </p>
          </v-col>
        </v-row>
        <v-row v-if="setBudget">
          <v-col class="ml-4" cols="12">
            <p class="left-align bolder">
              After you add or select a payment method it’s changed
              automatically, typically monthly. <a>Learn more</a>
            </p>
          </v-col>
        </v-row>
      </v-row>
      <v-list-item-title class="align-center d-flex mt-5 mb-4 margin">
      </v-list-item-title>
      <v-row class="ml-9 mr-2">
        <v-radio-group v-model="radioVall" column>
          <v-radio :value="false" color="primary">
            <template v-slot:label>
              <h4 class="ml-2 font-weight-regular" style="font-size: 16px; color: rgba(0, 0, 0, 6)">
                Yes, send me perfomance tips, promotional offers, and
                invitations to try new features
              </h4>
            </template>
          </v-radio>
        </v-radio-group>
        <v-radio-group v-model="radioVall" column>
          <v-radio color="primary">
            <template v-slot:label>
              <h4 class="ml-2 font-weight-regular" style="font-size: 16px; color: rgba(0, 0, 0, 6)">
                No, don’t send me perfomance tips, promotional offers, and
                invitations to try new features
              </h4>
            </template>
          </v-radio>
        </v-radio-group>
      </v-row>
      <v-row class="ml-4">
        <v-col class="align-center mt-5" cols="12">
          <v-btn
            class="button font-weight-bold next-btn font-family-raleway-medium"
            height="60"
            @click="handleSave"
            :loading="loading"
          >
            CONFIRM
          </v-btn>
        </v-col>
        <v-col class="align-center mt-3" cols="12">
          <v-btn
            style="background-color: white; box-shadow: none"
            @click="cancel = true"
            class="font-family-raleway-medium"
            >Cancel
          </v-btn>
          <div class="mt-3">
            <v-btn
              style="background-color: white; box-shadow: none"
              @click="$router.push('review-company')"
              class="font-family-raleway-medium"
              >Back
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <cancel-modal
      :cancel="cancel"
      :handle-click-cancel="handleClickBack"
      :cancel-modal="handleCancelModal"
    />
  </v-card>
</template>
<script>
import Vector from "/src/assets/img/card.svg";
import imgCampaignName from "/src/assets/img/imgCampaignName.svg";
import time from "/src/assets/img/time.svg";
import location from "/src/assets/img/location.svg";
import label from "/src/assets/img/label.svg";
import { getCities } from "@/services/autocomplete";
import { mapGetters } from "vuex";
const { campaignService } = require("../../services/campaign");

export default {
  async mounted() {
    localStorage.setItem("currentUrl", "payment")
    this.setUpStripe();
  },
  name: "BasicInfo",
  components: {
    CancelModal: () => import("../MainLanding/Modals/CancelModal"),
  },
  data() {
    return {
      loading: false,
      setBudget: true,
      radioVall: true,
      radioVal: false,
      cancel: false,
      companyName: "",
      url: "",
      keyword: "",
      chips: [],
      timeZones: [],
      name: "",
      country: "",
      city: "",
      address: "",
      postalCode: "",
      timeZone: "",
      countryValue: "",
      paymentMethodId: "",
      countrys: [],
      items: [
        {
          icon: Vector,
          title: "Card details",
          icon1: imgCampaignName,
          title1: "Billing country",
          icon2: time,
          title2: "Time zone",
          icon3: location,
          title3: "Billing address",
          icon4: label,
          title4: "Introductory offer",
        },
      ],
      imgCampaignName,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    setUpStripe: function () {
      let stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
      this.stripe = stripe;
      this.elements = stripe.elements();
      this.cardCvc = this.elements.create("cardCvc");
      this.cardExpiry = this.elements.create("cardExpiry");
      this.cardNumber = this.elements.create("cardNumber", {
        showIcon: true,
        placeholder: "Card number",
        style: {
          base: {
            backgroundColor: "transparent",
            iconColor: "#262626",
            fontFamily: "Raleway medium, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
          },
          invalid: {
            iconColor: "Red",
            color: "Red",
          },
        },
      });

      this.cardCvc.mount("#cardCVC");
      this.cardExpiry.mount("#cardExpire");
      this.cardNumber.mount("#cardNumber");
    },
    purchase: async function () {
      let valid = true;
      if (valid) {
        const result = await this.stripe.createPaymentMethod({
          type: "card",
          card: this.cardNumber,
          billing_details: {
            email: this.getUser.email,
          },
        });

        if (result.error) {
          this.$notify({
            group: "foo",
            title: result.error.message || "Something went wrong!",
            type: "error",
          });
          return false;
        }
        this.paymentMethodId = result.paymentMethod?.id;
        return true;
      } else {
        return false;
      }
    },
    handleClickBack() {
      this.$router.push("advertising-goals");
    },
    handleCancelModal() {
      this.cancel = false;
    },
    async handleSave() {
      this.loading = true;
      if (!(await this.purchase())) {
        this.loading = false;
        return;
      }
      const campaign = localStorage.getItem("campaign");
      const customerId = localStorage.getItem("customerId");
      const parseToObj = JSON.parse(campaign);
      try {
        await campaignService.create(
          {
            ...parseToObj,
            pmId: this.paymentMethodId,
          },
          customerId
        );
        localStorage.setItem("currentUrl", "all-done");
        await this.$router.push("all-done");
      } catch (err) {
        const errTitle = err.response?.data?.title;
        this.$notify({
          group: "foo",
          type: "error",
          title: errTitle || "Something went wrong",
        });
      }
      this.loading = false;
    },
    handleAdd() {
      if (this.keyword.length) {
        this.chips.push({
          text: this.keyword,
          isActive: true,
        });
      }
    },
    clearName() {
      this.companyName = "";
    },
    clearUrl() {
      this.url = "";
    },
  },
  watch: {
    timeZone(val) {
      console.log(val);
    },
    countryValue(val) {
      if (val) {
        getCities(val, true)
          .then((res) => {
            this.countrys = res;
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/style/style.scss";

.add-btn {
  background-color: transparent !important;
  box-shadow: none;
  margin-bottom: 30px !important;
  color: #e5e5e5;
  font-weight: bold;
  height: 30px !important;
}

.chip {
  height: 48px !important;
}

.next-btn {
  width: 240px !important;

  @media screen and (max-width: 600px) {
    width: 180px !important;
    height: 48px !important;
  }
}

.font-weight-bold {
  width: 100% !important;
  font-weight: bold;
}

.text {
  font-size: 16px;
}

.font {
  font-size: 16px;
}

.paymentInfo {
  font-size: 20px;
}

.title {
  margin-bottom: 20px;
}

.coles {
  margin-left: 20px;
}

.v-application .mt-9 {
  margin-top: 8px !important;
}

.v-application .mt-4 {
  margin-top: 40px !important;
}

.bolder {
  font-family: "Raleway light";
  color: rgba(0, 0, 0, 6);
}

.left-align {
  text-align: left;
}

.row + .row {
  margin-top: -20px;
}

.v-input--selection-controls {
  margin-top: 0;
  //padding-top: 4px;
}

.stripe-card {
  padding: 8px 12px;
  margin-left: 36px;
  margin-right: 36px;
  margin-bottom: 26px;
  border: solid 1px rgb(154, 152, 152);
  line-height: 1.5;
  height: 60px;
  align-items: stretch;
  min-height: 56px;
  cursor: text;
  border-radius: 15px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &.number {
    margin-top: 10px;
  }
}
</style>
